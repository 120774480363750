import React, { useState, useEffect, useContext } from 'react'
import environmentObject from '../../environment'
import { ProjectContext } from '../../App';
import {
  Grid,
  Column,
  Dropdown
} from '@carbon/react';



const WordCloudPage = () => {
   const { currentProject, setCurrentProject } = useContext(ProjectContext);
   const [ wordList, setWordList ] = useState([]);
   const thisProject = JSON.parse(localStorage.getItem('SNPOAcurrentProject'));
   const getAPIdata = () => {
       if (!thisProject) return false;
       fetch(environmentObject.route+'list/'+thisProject.id+'/words?delay=0&hidden=1&count=60', {})
          .then((response) => response.json())
          .then((data) => {
             setWordList(data);
          })
      .catch((err) => {
         console.log(err.message);
      });
   }

   const toggleHidden = (row) => {
     const thisProject = JSON.parse(localStorage.getItem('SNPOAcurrentProject'));
     const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+sessionStorage.getItem('token')
        },
        body: JSON.stringify({data: [row], project: thisProject.id, query: true})
    };

    fetch(environmentObject.route+'update/word/hidden?delay=0&hidden=1&count=60', requestOptions)
        .then(response => response.json())
        .then((data) => {
          if (data.status == 'error') return false;
          setWordList(data.query);
        })

   }

   useEffect(() => {
     getAPIdata();

   }, [currentProject]);

   return(
     <Grid>
       <Column sm={0} md={0} lg={2} />
       <Column sm={4} md={8} lg={12}>
     <div>
       <ul className={'word-list'}>
       {wordList && wordList.map(word =>
                     <li className={(word.hidden == '1' ? 'filtered' : '')+' '+((Date.now() - Date.parse(word.created) - (thisProject?.wordCloudDelay?.value * 1000)) > 0 ? '' : 'new-word')} key={'word_'+word.id} onClick={() => {toggleHidden(word)}}><strong>{word.word}</strong> ({word.count})</li>
                 )}
       </ul>
     </div>
     </Column>
     <Column sm={0} md={0} lg={2} />
   </Grid>
   )
};


export default WordCloudPage;
