import React, { useContext } from 'react'
import { ProjectContext } from '../../App';

const UserPage = () => {

  const { setToken, token } = useContext(ProjectContext);
  console.log(token);
  
  return <div>
    <h1>USER PAGE</h1>
    <button onClick={() => {
      window.location.href = '/';
      setToken('')
    } }>LogOut</button>

  </div>;
};

export default UserPage;
