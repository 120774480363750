import React, { useState, useEffect } from 'react'
import {
  Form,
  NumberInput,
  Select,
  SelectItem,
  TextInput,
  Button,
  Stack
} from '@carbon/react';

const mergeDeep = (...objects) => {
  const isObject = obj => obj && typeof obj === 'object';

  return objects.reduce((prev, obj) => {
    Object.keys(obj).forEach(key => {
      const pVal = prev[key];
      const oVal = obj[key];

      if (Array.isArray(pVal) && Array.isArray(oVal)) {
        prev[key] = pVal.concat(...oVal);
      }
      else if (isObject(pVal) && isObject(oVal)) {
        prev[key] = mergeDeep(pVal, oVal);
      }
      else {
        prev[key] = oVal;
      }
    });

    return prev;
  }, {});
}

const DynamicForm = (props) => {
  const defaultValues = props.defaultValues ? JSON.parse(props.defaultValues) : {};
  const onSubmit = props.onSubmit;
  const [ forms, setForms ] = useState(defaultValues);

  const handleOnChange = (evt) => {
    var newForms = {}
     for (const [form, content] of Object.entries(forms)) {
      if (evt.target.id !== form) newForms[form] = content;
      if (evt.target.id === form) newForms[form] = {...content, value: evt.target.value}
    }
    setForms(newForms);
  };

  const handleSubmit = (e) => {
    const data = new FormData(e.target);
    e.preventDefault();
    var object = {};
    data.forEach((value, key) => object[key] = value);
    onSubmit(object);
  };


  useEffect( () => {
    const mergedValues = mergeDeep(defaultValues, props.currentValues)
    setForms(mergedValues);
  }, [props.currentValues] );

  var inputList = [];

  for (const [key, value] of Object.entries(forms)) {

    const props = {
      name: key,
      label: value.label,
      labelText: value.label,
      id: key,
      key: key,
      value: (typeof value.value !== 'undefined' ? value.value : value.defaultValue),
      onChange: handleOnChange,
    }
    if (value.max) props.max = value.max
    if (value.min) props.min = value.min
    if (value.required) props.required = true
    if (value.pattern) props.pattern = value.pattern
    if (value.items) props.items = value.items;
    switch (value.type) {

      case 'number' :
        delete props.labelText;
        inputList.push(<NumberInput  {...props} />)
        break;

      case 'list' :
        var itemList = []
        for (const [ikey, ivalue] of Object.entries(value.items)) {
          itemList.push(<SelectItem key={key+'_'+ikey} value={ikey} text={ivalue }/>);
        }
        inputList.push(
          <Select  {...props} >
            {itemList}
          </Select>
        )
        break;

      case 'text' :
        inputList.push(<TextInput  {...props} />)
        break;

      default :
        break;
      }
    }

  return (
    <Form onSubmit={handleSubmit}>
      <Stack gap={7}>
        {inputList}
        <Button type="submit">Update Project</Button>
      </Stack>
    </Form>);

}

export default DynamicForm;
