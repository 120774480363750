import React, { useState, useEffect, useContext } from 'react'
import environmentObject from '../../environment'
import { ProjectContext } from '../../App';
import { useNavigate } from "react-router-dom";

import {
  Header,
  HeaderContainer,
  HeaderName,
  HeaderNavigation,
  HeaderMenuButton,
  HeaderMenuItem,
  HeaderGlobalBar,
  HeaderGlobalAction,
  HeaderPanel,
  Switcher,
  SwitcherDivider,
  SwitcherItem,
  SkipToContent,
  SideNav,
  SideNavItems,
  HeaderSideNavItems,
  Dropdown,
} from '@carbon/react';
import { Switcher as SwitcherIcon, UserAvatar } from '@carbon/react/icons';
import { Link } from 'react-router-dom';



const PageHeader = () => {
  const { currentProject, setCurrentProject } = useContext(ProjectContext);
  const [projects, setProjects] = useState([]);
  const [projectRouting, setProjectRouting] = useState(false);
  const [currentSidePanel, setCurrentSidePanel] = useState(false);
  const { setToken, token } = useContext(ProjectContext);
  const navigate = useNavigate();

  useEffect(() => {
     fetch(environmentObject.route+'list/projects', {
     })
        .then((response) => response.json())
        .then((data) => {
          if (data) {
           var projects = []
          for (const [key, value] of Object.entries(data)) {
              projects.push({text:value.name.value, id:key, ...value});
           }
           projects.push({text:'New Project', id:'new-project'})
           setProjects(projects);
         }
        })
        .catch((err) => {
           console.log(err.message);
        });
  }, []);

  useEffect(() => {
    if ( JSON.parse(localStorage.getItem('SNPOAcurrentProject')) === currentProject || !projectRouting) return;
    setProjectRouting(false);
    navigate('/project/'+(currentProject ? currentProject.id : ''));
  }, [currentProject]);

  return (
  <HeaderContainer
    render={({ isSideNavExpanded, onClickSideNavExpand }) => (
      <Header aria-label="Steady Now OnAir">
        <SkipToContent />
        <HeaderName as={Link} to="/" prefix="Steady Now">
          OnAir
        </HeaderName>
        <HeaderGlobalBar>
            <Dropdown
              aria-label="Projects"
              id="projects-selector"
              type="inline"
              items={projects}
              itemToString={(item) => (item ? item.text : '')}
              label="Dropdown menu of Projects"
              onChange={({ selectedItem }) => {
                localStorage.setItem('SNPOAcurrentProject', JSON.stringify(selectedItem))
                setProjectRouting(true);
                setCurrentProject(selectedItem);
              }}
              selectedItem={JSON.parse(localStorage.getItem('SNPOAcurrentProject'))}
              titleText="Projects"
            />

            <HeaderGlobalAction aria-label="App Switcher" tooltipAlignment="end" aria-label={currentSidePanel == 'apps' && isSideNavExpanded ? 'Close switcher' : 'Open switcher'} aria-expanded={currentSidePanel == 'apps' && isSideNavExpanded } isActive={currentSidePanel == 'apps' && isSideNavExpanded }
              onClick={() => {
                if (currentSidePanel == 'apps' && isSideNavExpanded ) {
                  console.log('apps');
                  onClickSideNavExpand();
                } else if (!isSideNavExpanded && currentSidePanel == 'apps'){
                  onClickSideNavExpand();
                } else if (!isSideNavExpanded && currentSidePanel != 'apps'){
                  setCurrentSidePanel('apps');
                  onClickSideNavExpand();
                } else {
                  setCurrentSidePanel('apps');
                }
              }}
              >
              <SwitcherIcon size={20} />
            </HeaderGlobalAction>
        </HeaderGlobalBar>
        <HeaderPanel expanded={isSideNavExpanded} onHeaderPanelFocus={()=> false} >

            {
              currentSidePanel == 'user' ? (
                  <></>
                ):(
                 <Switcher aria-label="Switcher" >
                   <SwitcherItem aria-label="Current Project Settings" as={Link} to={"/project/"+( JSON.parse(localStorage.getItem('SNPOAcurrentProject'))?.id )} onClick={onClickSideNavExpand}>Current Project Settings</SwitcherItem>
                   <SwitcherDivider />
                   <SwitcherItem aria-label="Submission" as={Link} to="/moderation/submission" onClick={onClickSideNavExpand}>Submissions</SwitcherItem>
                   <SwitcherItem aria-label="Word Cloud" as={Link} to="/moderation/wordcloud" onClick={onClickSideNavExpand}>Word Cloud</SwitcherItem>
                 </Switcher>
               )
            }

        </HeaderPanel>
      </Header>
    )}
  />
)};

export default PageHeader;
