import React, { useState, useEffect }  from 'react';
import './app.scss';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { Content, Theme, Modal } from '@carbon/react';
import LandingPage from './content/LandingPage';
import ProjectPage from './content/ProjectPage';
import {UserPage, UserAdmin} from './content/UserPage';
import SubmissionPage from './content/SubmissionPage';
import WordCloudPage from './content/WordCloudPage';
import Header from './components/Header';
import LoginBox from './components/Login';
import jwt_decode from "jwt-decode";


export const ProjectContext = React.createContext(null);
export const LoginContext = React.createContext(null);


const App = () => {
    const [currentProject, setCurrentProject] = useState(null);
    const [token, setToken] = useState(sessionStorage.getItem('token'));
    const [messageModalContent, setMessageModalContent] = useState('This is a test message');
    const [messageModalOpen, setMessageModalOpen] = useState(false);


    useEffect(() => {

      if (token) {
        const decodedToken = jwt_decode(token);
        if (decodedToken.exp < Date.now()/1000) {
          sessionStorage.removeItem('token');
        } else {
          sessionStorage.setItem('token', token);
        }
      } else {
        sessionStorage.removeItem('token');
      }

    }, [token]);


    // if (!token) {
    //   return (
    //     <LoginContext.Provider value={{
    //       token: token,
    //       setToken: setToken,
    //       setMessageModalContent:setMessageModalContent,
    //       setMessageModalOpen:setMessageModalOpen
    //      }}>
    //       <Modal open={messageModalOpen} passiveModal modalHeading={messageModalContent} onRequestClose={() => {setMessageModalOpen(false)}}></Modal>
    //       <LoginBox />
    //     </LoginContext.Provider>
    //   );
    // }

    const decodedToken = false //jwt_decode(token);

    return (
      <BrowserRouter>
        <ProjectContext.Provider value={{
            currentProject: currentProject,
            setCurrentProject: setCurrentProject,
            setToken: setToken,
            token: decodedToken,
            setMessageModalContent:setMessageModalContent,
            setMessageModalOpen:setMessageModalOpen
          }}>
          <Theme theme="g100">
            <Header />
          </Theme>
          <Content>
            <Modal open={messageModalOpen} passiveModal modalHeading={messageModalContent} onRequestClose={() => {setMessageModalOpen(false)}}></Modal>

              <Routes>
                <Route exact path="/" element={<LandingPage />} />
                <Route path="/moderation/submission" element={<SubmissionPage />} />
                <Route path="/moderation/wordcloud" element={<WordCloudPage />} />
                <Route path="/project/:project" element={<ProjectPage />} />
                <Route path="/user/profile" element={<UserPage />} />
                {false /*JSON.parse(decodedToken.admin)*/ ?
                  <Route path="/user/admin" element={<UserAdmin />} />
                : <></> }

              </Routes>

          </Content>
        </ProjectContext.Provider>
      </BrowserRouter>
    );
  }


export default App;
