import React, { useState, useEffect, useContext } from 'react'
import { useParams, useNavigate } from "react-router-dom";
import environmentObject from '../../environment'
import DynamicForm from '../../components/DynamicForm';
import { ProjectContext } from '../../App';
import {
  Grid,
  Column,
  Dropdown,
  Stack,
} from '@carbon/react';


const ProjectPage = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [ metaList, setMetaList ] = useState({});
    const newProject = (params.project === 'new-project' ? true : false);
    const { currentProject, setCurrentProject } = useContext(ProjectContext);
    var defaultProjectValues = {
      name: {
        label: 'Project Name',
        description: 'Human readable name for the Project',
        defaultValue: 'Project Name',
        type: 'text',
        required: true,
      },
      colorTheme: {
        label: 'Color Theme',
        description: 'Color theme for On Air Graphics',
        defaultValue: 'blue',
        type: 'list',
        items: {
          red: 'Red',
          magenta: 'Magenta',
          purple:'Purple',
          blue: 'Blue',
          cyan: 'Cyan',
          teal: 'Teal',
          green: 'Green'
        }
      },
      wordCloudItems: {
        label: 'Word Cloud Items',
        description: 'Number of Word Cloud Items to display',
        defaultValue: 20,
        type: 'number',
        required: true,
        min: 0,
        max: 60,
      },
      wordCloudDelay: {
        label: 'Word Cloud Delay',
        description: 'Time delay for new words to be included in the on air graphics',
        defaultValue: 20,
        type: 'number',
        required: true,
        min: 0,
        max: 60,
      },

    }

    if (newProject) defaultProjectValues = {...{newproj: {label: 'New Project ID', description: 'alpha numeric string for project identification', type:'text', defaultValue:'', required: true}}, ...defaultProjectValues}

    const getAPIdata = () => {
        if (newProject) return;
        if (params.project === 'undefined') return;
        fetch(environmentObject.route+'list/'+params.project+'/meta', {})
           .then((response) => response.json())
           .then((data) => {
              setMetaList(data);
           })
       .catch((err) => {
          console.log(err.message);
       });
    }

    const formSubmit = (props) => {
      var project = (newProject ? props.newproj : params.project);
      if (newProject) delete props.newproj;
      const requestOptions = {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
           'Authorization': 'Bearer '+sessionStorage.getItem('token')
         },
         body: JSON.stringify({data: props, project: project, query: true})
     };

     fetch(environmentObject.route+'update/project', requestOptions)
         .then(response => response.json())
         .then((data) => {
           if (data.status == 'error') return false;
           setMetaList(data.query);
           if (JSON.parse(localStorage.getItem('SNPOAcurrentProject')).id === project) {
             var oldversion = JSON.parse(localStorage.getItem('SNPOAcurrentProject'));
             localStorage.setItem('SNPOAcurrentProject', JSON.stringify({...oldversion, ...data.query }))
           }
         })

    }

    useEffect( () => {
      if (newProject) setMetaList(defaultProjectValues);
      if (!newProject) getAPIdata();
    }, [useParams()] );

    useEffect(() => {
      if (!newProject) return;
      if (!metaList || typeof metaList === 'undefined' || !metaList?.newproj?.value ) return;
      navigate('/project/'+(metaList ? metaList?.newproj?.value : ''));
    }, [metaList]);

    return(
      <Grid>
        <Column sm={0} md={0} lg={2} />
        <Column sm={4} md={8} lg={12}>
      <div>
        <Stack gap={7}>
          <DynamicForm defaultValues={JSON.stringify(defaultProjectValues)} currentValues={(newProject ? {} : metaList)} onSubmit={formSubmit} />
        </Stack>
      </div>
      </Column>
      <Column sm={0} md={0} lg={2} />
    </Grid>
    )
};

export default ProjectPage;
