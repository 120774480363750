import React, { useState, useEffect, useContext } from 'react'
import { TrashCan, Save, Download, Add, Subtract, ThumbsUp, ThumbsDown, Menu } from '@carbon/icons-react';
import {DataTable, Button, Table, TableBatchAction, TableBatchActions, TableBody, TableCell, TableContainer, TableHead, TableHeader, TableRow, TableSelectAll, TableSelectRow, TableToolbar, TableToolbarAction, TableToolbarContent, TableToolbarSearch, TableToolbarMenu, MenuButton, MenuItem } from '@carbon/react';
import environmentObject from '../../environment'
import { ProjectContext } from '../../App';
import {
  Grid,
  Column,
  Dropdown,
  Link,
  Pagination,
} from '@carbon/react';



const SubmissionPage = () => {
   const [currentData, setCurrentData] = useState([]); // Data from API
   const [activeData, setActiveData] = useState([]); // Current Active Dataset
   const [currentPageData, setCurrentPageData] = useState([]); // Current Displayed Data
   const [currentPage, setCurrentPage] = useState(1);
   const [currentPageSize, setCurrentPageSize] = useState(20);
   const [currentActiveCount, setActiveItemCount] = useState();
   const { currentProject, setCurrentProject } = useContext(ProjectContext);

   const batchActionClick = (action, selectedRows) => () => {
     var thisProject = JSON.parse(localStorage.getItem('SNPOAcurrentProject'));
     const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+sessionStorage.getItem('token')
        },
        body: JSON.stringify({data: selectedRows, project: thisProject.id, query: true})
    };

    fetch(environmentObject.route+'update/submission/'+action, requestOptions)
        .then(response => response.json())
        .then((data) => {
          if (data.status == 'error') return false;
          setCurrentData(data.query)
          setActiveData(data.query)
        })
   };

   const modFilterRows = (atts) => {
          const inputVal = atts.target.value;
          var thisdata = []
          currentData.map((item) => {
            if (item.comment.toLowerCase().includes(inputVal.toLowerCase())) {
              thisdata.push(item);
              return true;
            }
            return false;
          });
         setActiveData(thisdata);
         setCurrentPage(1);
      }

  const getAPIdata = () => {
      var thisProject = JSON.parse(localStorage.getItem('SNPOAcurrentProject'));
      console.log(thisProject);
      fetch(environmentObject.route+'list/'+thisProject.id+'/submission?='+Date.now(), {})
         .then((response) => response.json())
         .then((data) => {
            setCurrentData(data) // Save data from API
            setActiveData(data);
         })
     .catch((err) => {
        console.log(err.message);
     });
  }

   useEffect(() => {
     getAPIdata();

   }, [currentProject]);

   useEffect(() => {
      setCurrentPageData(activeData.slice(currentPage*currentPageSize - currentPageSize,currentPage*currentPageSize));
      setActiveItemCount(activeData.length);
   }, [currentData, activeData, currentPage, currentPageSize]);


  return (
    <Grid className="submission-page" fullWidth>
      <Column lg={16} md={8} sm={4} className="landing-page__r3">
      <DataTable
        headers={[

          {
            header: 'Comment',
            key: 'comment'
          },
          {
            header: 'Date',
            key: 'datetime'
          },
          // {
          //   header: 'Submitter',
          //   key: 'submitter'
          // },
          // {
          //   header: 'Location',
          //   key: 'location'
          // },
          {
            header: 'Approved',
            key: 'approved'
          },
          {
            header: 'Action',
            key: 'action'
          }
        ]}
        rows={currentPageData}
        >
        {({
          rows,
          headers,
          getHeaderProps,
          getRowProps,
          getSelectionProps,
          getToolbarProps,
          getBatchActionProps,
          onInputChange,
          selectedRows,
          getTableProps,
          getTableContainerProps
        }) => {
          const batchActionProps = getBatchActionProps();
          return <TableContainer title="Content Submissions" description="Admin interface to moderate individual content submissions. This page is a work in progress and feature and scope hasn't been fully defined." {...getTableContainerProps()}>
                <TableToolbar {...getToolbarProps()}>
                  <TableBatchActions {...batchActionProps}>
                    <TableBatchAction tabIndex={batchActionProps.shouldShowBatchActions ? 0 : -1} renderIcon={TrashCan} onClick={batchActionClick('delete', selectedRows)}>
                      Delete
                    </TableBatchAction>
                    <TableBatchAction iconDescription="Add" tooltipPosition="bottom" tabIndex={batchActionProps.shouldShowBatchActions ? 0 : -1} renderIcon={Add} onClick={batchActionClick('approve', selectedRows)}>
                      Approve
                    </TableBatchAction>
                    <TableBatchAction iconDescription="Subtract" tooltipPosition="bottom" tabIndex={batchActionProps.shouldShowBatchActions ? 0 : -1} renderIcon={Subtract} onClick={batchActionClick('unapprove', selectedRows)}>
                      Un-approve
                    </TableBatchAction>
                  </TableBatchActions>
                  <TableToolbarContent aria-hidden={batchActionProps.shouldShowBatchActions}>
                    <TableToolbarSearch tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0} onChange={modFilterRows} />
                  </TableToolbarContent>
                </TableToolbar>
                <Table {...getTableProps()} aria-label="submission table">
                  <TableHead>
                    <TableRow>
                      <TableSelectAll {...getSelectionProps()} />
                      {headers.map((header, i) => {
                        switch (header.key) {

                          case 'action' :
                            return <TableHeader key={i} {...getHeaderProps({header})}>
                              {header.header}
                            </TableHeader>
                            break;

                          default:
                          return <TableHeader key={i} {...getHeaderProps({header, isSortable: true})}>
                            {header.header}
                          </TableHeader>
                          break;
                      }}
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, i) => <TableRow key={i} {...getRowProps({
                  row
                })}>
                        <TableSelectRow {...getSelectionProps({
                    row
                  })} />
                        {row.cells.map(cell => {

                          switch (cell.info.header) {
                            case 'action' :
                              return <TableCell key={cell.id}>
                                      <MenuButton label="Menu" kind="ghost">
                                        <MenuItem label="Approve" onClick={batchActionClick('approve', [row])} />
                                        <MenuItem label="Un-approve" onClick={batchActionClick('unapprove', [row])} />
                                        <MenuItem label="Delete" onClick={batchActionClick('delete', [row])}/>
                                      </MenuButton>
                                    </TableCell>
                              break;

                            case 'approved':
                              return <TableCell key={cell.id}>{JSON.parse(cell.value) ? <ThumbsUp /> : <ThumbsDown />}</TableCell>
                              break;

                            default :
                              return <TableCell key={cell.id}>{cell.value}</TableCell>

                              break;

                          }

                        })}
                      </TableRow>)}
                  </TableBody>
                </Table>
              </TableContainer>
        }}
        </DataTable>
        <Pagination
          backwardText="Previous page"
          forwardText="Next page"
          itemsPerPageText="Items per page:"
          onChange={(atts) => {
            setCurrentPage(atts.page);
            setCurrentPageSize(atts.pageSize)
          }}
          page={currentPage}
          pageSize={currentPageSize}
          pageSizes={[
            20,
            40,
            50,
            75,
            100
          ]}
          size="md"
          totalItems={currentActiveCount}
        />
      </Column>
  </Grid>
  );
};


export default SubmissionPage;
